<template>
  <Modal :modal-active="showCreatePasswordModal">
    <template v-slot:default="{}">
      <div class="row mt-2 py-3" style="margin-left: unset">
        <div class="">
            <div class="row col-12 Text mt-1">
              <span class="container-fluid mt-1 text-step ">
                Agora é só digitar a senha:
              </span>               
            </div>
            <div  class="mt-2 password-input-block">
              <span class="text-input">Senha*</span>
              <div>
                <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="customer.password"
                  @input="validatePassword"
                  placeholder="Senha"
                  class="col-12 form-control p-1 text-input border rounded"
                />
                <span @click="togglePasswordVisibility" class="px-1 input-icon-senha">
                  <i :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                </span>
              </div>              
            </div>
               <div v-if="!passwordIsValid" class="mt-2">
                <span class="text-alert-password">
                  A senha deve conter pelo menos um caractere especial, uma letra
                  maiúscula e um número.
                </span>
              </div>
            <div class="mt-2 password-input-block">
              <span class="text-input">Repetir senha*</span>
              <div>
                <input
                  :type="showPasswordCreateUser ? 'text' : 'password'"
                  v-model="customer.confirmPassword"
                  @input="validateConfirmPassword"
                  placeholder="Senha"
                  class="col-12 form-control p-1 text-input border rounded"
                />
                <span  @click="togglePasswordVisibilityConfirm" class="px-1 input-icon-senha">
                  <i :class="showPasswordCreateUser ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                </span>
              </div>             
            </div>
             <div v-if="!confirmPasswordIsValid">
                <span class="text-alert-password">
                  Atenção. A senha deve ser idêntica à anterior.
                </span>
              </div>
          </div>     
          <button
            type="button"
            @click="createPassword"
            class="btn border rounded col-12 mt-4 mb-3 style-button">
            <span v-if="!processing">Cadastrar</span>
              <span v-else>
                <div class="text-center">
                  <strong class="text-sm ">Aguarde o processamento......</strong>
                   <div class="spinner-border ml-auto" role="status" aria-hidden="true" style="width: 16px; height: 16px;"></div>
                </div>
              </span>            
          </button>
        <VerificationTokenSendEmail 
          @opemModalSendCode="hidesendCode"
          v-if= showSendCodeModal  
          :closeModalSendEmail="closeModalSendEmail"
        />
      </div>
    </template>
  </Modal>
  <div>  
    <Login 
      v-if= showLoginModal  
      :close="close" 
    />
  </div>
</template>
<script>
import Modal from "@/components/Modal/Modal";
import { createPassword } from "@/modules/auth/services/auth.customer.service.js"
import VerificationTokenSendEmail from "@/components/Login/VerificationTokenSendEmail.vue"
import Login from "@/components/Login/Login.vue"
export default {
  name: "CreatePassword",
  data() {
		return {
			showPasswordCreateUser: false,
			showPassword: false,
      isContinueButtonDisabled: false,
      passwordsMatch: false,
      processing: false,
      try: 0,
      timerRest: 0,
      timer: null,
      confirmPasswordIsValid: true,
      passwordIsValid: true,
      showCreatePasswordModal: true,
      showSendCodeModal: false,
      showLoginModal: false,
      customer: {        
        password: "",
        confirmPassword: "",
        id: ""
      },
      tagOrigin: "Fluxo de Vendas do TradeAppOne",
    };
  },
  components: {
    Modal,
    VerificationTokenSendEmail,
    Login
  },
  props:{
    closeModalCreatePassword: {
      type: Function
    }
  },
  methods: {
    createPassword() {
      this.disableButton();
      if (this.passwordsMatch) {
        const payload = this.getPasswordPayload();
        if (payload.customer) {
          this.submitCreatePassword(payload)
            .then(() => {
              this.$swal("Senha cadastrada com sucesso! Você pode realizar o login.", " ", "success");
              this.openLogin();
            })
            .catch((error) => {
              this.$swal("Ocorreu um erro ao cadastrar a senha.", error.message, "error");
            });
        }
      } else {
        this.$swal("Ops! Parece que as senhas não coincidem. Por favor, verifique-as novamente.", " ", "error");
        this.enableButton();
      }
    },
    close(){
      this.showLoginModal = false;
    },
    openLogin(){
      this.showCreatePasswordModal= false;
      this.showLoginModal=true;
    },
    hideopenModalPassword(){     
       this.showCreatePasswordModal = true;
    },
       openModalPassword2(){
      this.showCreatePasswordModal= true;
      this.$emit('openModalPassword2');
    }, 
    async submitCreatePassword(payload) {
      try {
        await createPassword(payload.customer, payload.password);
      } catch (error) {
        console.log('Erro na criação de senha', error);
      }
    },
    getPasswordPayload() {
      const customer = this.$store.getters["customer/getCustomer"];
      if (customer && customer.id) {
        return {
          customer: customer.id,
          password: this.customer.password,
        };
      }else {
        console.log("Erro: 'customer' não está presente nos dados.");
        return null;
      }
    },
    closeModalSendEmail() {
      this.showSendCodeModal = false;
      this.showCreatePasswordModal = false;
    },
    opemModalSendCode(){
      this.showSendCodeModal= true
      this.$emit('opemModalSendCode')
    },    
    validatePassword() {
      const password = this.customer.password;
      const especialCaracter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const upperLeter = /[A-Z]/.test(password);
      const number = /\d/.test(password);
      const size = password.length >= 8;
      this.passwordIsValid = especialCaracter && upperLeter && number && size;
       if (this.passwordIsValid && this.customer.password === this.customer.confirmPassword) {
      this.passwordsMatch = true;
    } else {
      this.passwordsMatch = false;
    }

    return this.passwordIsValid;
    },
    validateConfirmPassword() {
      const confirmPassword = this.customer.confirmPassword;
      const especialCaracter = /[!@#$%^&*(),.?":{}|<>]/.test(confirmPassword);
      const upperLeter = /[A-Z]/.test(confirmPassword);
      const number = /\d/.test(confirmPassword);
      const size = confirmPassword.length >= 8;
      this.confirmPasswordIsValid = especialCaracter && upperLeter && number && size;
        if (this.confirmPasswordIsValid && this.customer.password === this.customer.confirmPassword){
          this.passwordsMatch = true;
        } else {
          this.passwordsMatch = false;
        }
      return this.confirmPasswordIsValid;
    },
    userPasswordValidate() {
      const password = this.customer.password;
      const size = password.length >= 8;
      this.passwordIsValid = size;
      if (this.passwordIsValid) {
        return true;
      } else {
        return false;
      }
    },
    disableButton() {
      this.processing = true;
    },
    enableButton() {
      this.processing = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordVisibilityConfirm() {
      this.showPasswordCreateUser = !this.showPasswordCreateUser;
		}
  }
};
</script>
<style lang="scss" scoped>
.text-input {
	font-size: 11px;
	color: rgb(88, 87, 87);
}
.text {
	color: #070707e7;
	font-size: 12rem;
}
.text-alert-password {
	color: crimson;
	font-size: 10px;
}
.input-icon-senha {
  font-size: 14px;
  position: absolute;
  top: 73%;
  right: 17px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}
.style-button{
  color: #fff;
  background-color: #1d3c85;
}
.text-step{
	font-size: 17px;
	color: #1d3c85;
  font-weight: 500;
}
.icon-close{
  float: right;
  margin-right: -22px;
  cursor: pointer;
  color: #1d3c85;
  font-size: 16px
}
.close-button {
  display: inline-block;
  top: 0;
  right: 0;
  width: 25px;
  height: 26px;
  background-color: #fff;
  border: 2px solid #1d3c85;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.password-input-block {
  position: relative;
  width: 100%;
}
</style>
