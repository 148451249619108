import Api from "@/services/api";
export async function createPassword( id, password) {
  const apiUrl = "api/customer/password"; 
  return  Api.post(apiUrl, { customer: {id, password} });
}
export async function verifyLogin(id, password) {
  const apiUrl = "api/customer/verify-login"; 
  return  Api.post(apiUrl, { customer: id, password });
}
export async function sendForgotPasswordEmail(email) {
  const apiUrl = "api/customer/send-forgot-password-email"; 
  return  Api.post(apiUrl, { customer: {email} });
}
export  async function updatePassword  (id, currentPassword, newPassword) {
  const apiUrl = "api/customer/password/update"; 
  return  Api.post(apiUrl, { customer: {id, currentPassword, newPassword}});			    
}
export async function forgotPassword(email, password, passwordRepeat, code) {
  const apiUrl = "/api/customer/forgot-password"; 
  return Api.post(apiUrl, { customer: {email, password, passwordRepeat, code}});
}
export  async function validateEmail(id, code) {
  const apiUrl = "/api/customer/validate-email-code"; 
  return  Api.post(apiUrl, { customer: id, code});
}
export async function reSendEmail(id) {
  const apiUrl = "/api/customer/send-validate-email"; 
  return  Api.post(apiUrl, { customer: id});     
}
export default {
  createPassword,
  updatePassword,
  forgotPassword,
  validateEmail,
  reSendEmail,
  verifyLogin,
  sendForgotPasswordEmail
};