<template>
  <Modal :modal-active="showModalSendTokenEmailChangePassword">
    <template v-slot:default="{}">
      <div style="margin-left: unset">
        <div class="row col-12  container mt-1">
          <span class="container-fluid mt-3 text-updated ">
            Alteração de senha
            <i class="fas fa-times icon-close  close-button" @click="closeSendTokenEmail"></i>
          </span>               
        </div>
        <div class="container">
          <div class="mt-1 text">*Digite seu e-mail</div>
          <div class="mt-1 ">
            <input
              type="email"
              class="form-control col-12 text-input"
              id="email"
              placeholder="email@email.com.br"
              v-model="customer.email"
            />
          </div>
          <div class="col-12 row justify-content-center mt-2">
            <button
              class="btn border rounded col-5 mt-2 mb-4 mx-2" style="background-color: #9db8f7; color: #fff;"
              @click="returLogin">
              Voltar
            </button>
            <button
              class="btn border rounded col-6 mt-2 mb-4 style-button"
              @click="changeCodeByEmail">
              Enviar
            </button>
          </div>
        </div>          
      </div>      
    </template>    
  </Modal>
<ModalSendEmailSuccess v-if="showModalSendEmailSuccess"/>
</template>
<script>
import { mapGetters } from "vuex";
import Modal from "@/components/Modal/Modal.vue";
import ModalSendEmailSuccess from "@/components/Login/ModalSendEmailSuccess.vue";
import { sendForgotPasswordEmail }from "@/modules/auth/services/auth.customer.service.js"
export default {
  name: "ModalSendEmail",
  emits: ['returnLogin'],
  components: {
    Modal,
    ModalSendEmailSuccess
  },
  data() {
    return {
      showModalSendTokenEmailChangePassword: true,
      showModalSendEmailSuccess:false,
      customer: {
        email: "",
      }
    };
  },
    props:{
    closeSendTokenEmail: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      customerComputed: "customer/getCustomer",
      customerAddressComputed: "customer/getCustomerAddress",
      isAuthenticatedCustomerComputed: "auth/isAuthorizationCustomer",
    }),
    getPayloadEmail() {
      return {
        email: this.customer.email
      };
    }
  },
  methods: {
    async changeCodeByEmail() {
      try {
        await sendForgotPasswordEmail(this.customer.email);
        this.updatePassword()
        this.openModalSucess()        
      } catch {
        this.$swal("O email informado não foi encontrado, tente novamente.");
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    openModalSucess(){
      this.showModalSendEmailSuccess = true;
    },
    returLogin(){
      this.showModalSendTokenEmailChangePassword = false;
      this.$emit('returnLogin')
    },
    updatePassword() {
      this.showModalSendTokenEmailChangePassword = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.button-accept {
  background-color: #1d3c85;
  color: #fff;
}
.icon-close {
  float: right;
  margin-top: -1px;
  cursor: pointer;
  color:#1d3c85;
}
.text-email {
  color: #1d3c85;
  font-size: 16px;
  font-weight: 700;
}
.text {
  color: rgb(150, 148, 148);
  font-size: 12px;
  font-weight: 700;
}
.text-input {
  font-size: 11px;
  color: rgb(88, 87, 87);
} 
.text-updated{
  color: #1d3c85; 
  font-size: 16px; 
  font-weight: 500;
}
.icon-close{
  float: right;
  margin-right: -22px;
  cursor: pointer;
  color: #1d3c85;
  font-size: 16px
}
.close-button{
  display: inline-block;
  top: 0;
  right: 0;
  width: 26px;
  height: 27px;
  background-color: #fff;
  border: 2px solid #1d3c85;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style-button{
  color: #fff;
  background-color: #1d3c85;
}
</style>
