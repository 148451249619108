<template>
  <Modal :close="closeModal" :modal-active="getShowingRegionalizationModalComputed">
    <template v-slot:default="{ close }">
      <div class="container-fluid py-3" style="margin-top: 13px;">
        <div class="row">
          <span class=" text-start mt-1 text-modal-private">
            Vamos verificar os planos e produtos disponíveis no seu endereço
          </span>
        </div>
        <div class="container mt-3" style="color:#1D3C85; font-size: 12px;">
          CEP
        </div>
        <div class="container-fluid mt-1">
          <input type="text" label="CEP" class="form-control text-input" placeholder="00000-000" v-model="addresses.cep" v-mask="'#####-###'" />
          <small class="form-text text-danger">{{res.getErrors("cep")[0]}}</small>
          <a href="https://buscacepinter.correios.com.br/app/endereco/index.php"  target="_blank">
            <div class=" mt-3" style="color:#1D3C85; font-size: 12px;">
              Não sei meu CEP
            </div>
          </a>
          <div class="d-flex justify-content-around
            mt-3">
            <b-button 
                type="button" 
                class="btn border rounded col-12 " 
                style="color: #FFFF;
                background-color: #1D3C85;" 
                :disabled="processing" 
                @click="close">
              <span v-if="!processing"> Avançar</span>
              <span v-else>
                <div class="text-center">
                    <strong class="text-sm">Aguarde o processamento......</strong>
                      <div class="spinner-border ml-auto"  role="status" aria-hidden="true" style="width: 16px; height: 16px;">
                  </div>
                </div>
              </span>
                 
             
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>  
<script>
import suite from "@/services/validations/adressValidations.js"
import { mapGetters, mapActions, mapState } from "vuex"
import Modal from "@/components/Modal/Modal"
import { getAndDisplayLocationInfo, getNavigation} from "@/services/DeviceInformation/DeviceInformation.service";

export default {
  name: "RegionalizationModal",
  components: {
    Modal
  },
  data() {
    return {
      res: suite.get(),
      addresses: {
        cep: "",
        id: null
      },
      addressId: null,
      processing: false,
      hability: false
    } 
  },
  computed: {
    ...mapState({
      getShowingRegionalizationModalComputed: (state) => state.address.showingRegionalizationModal || false
    }),
    ...mapGetters({
      getRegionalizationModalComputed: 'getRegionalization',
      getAddressComputed: 'address/getAddresses',
      getRegionalizationModalCallbackComputed: 'address/getRegionalizationModalCallback',
      getAvailableProductTypeDecodedComputed: 'saleFlow/product/getAvailableProductTypeDecoded'
    })
  },
  methods: {
    ...mapActions({
      getAllProductsCatalogAction: 'saleFlow/getAllProductsCatalog',
      findByPostalCodeAction: 'address/findByPostalCode',
      setCurrentAddressAction: 'address/setCurrentAddress',
      closeRegionalizationModalAction: 'address/closeRegionalizationModal',
      openRegionalizationModalAction: 'address/openRegionalizationModal',
    }),
    closeModal() {
      this.enableButton();
      const postalCode = this.addresses.cep;

      try {
        this.findByPostalCodeAction(postalCode)
          .then(() => this.setCurrentAddressAction(this.getAddressComputed))
          .then(() => this.getRegionalizationModalCallbackComputed())
          .then(() => {this.closeRegionalizationModalAction(postalCode)
          .then(() => this.disableButton());
          })
          
          .catch(() => {  
            this.$swal('CEP não encontrado. Por favor, verifique e tente novamente.', '','warning', this.openRegionalizationModalAction())
            this.disableButton();
          });
      } catch (error) {
        console.error('Erro ao processar a requisição:', error);
      }
    },
    async byPostalCode() {
      try {
        const position = await getNavigation();
        const locationInfo = await getAndDisplayLocationInfo(position);

        if (locationInfo) {
          const { address } = locationInfo;
          const postalCode = address?.postcode || '';
          this.addresses.cep = postalCode;
        }
      } catch (error) {
        console.error("Erro ao obter informações de localização:", error);
      }
    },
    validateFormAddress(field) {
      this.resA = suite(
        {
          cep: this.addresses.cep,
        },
        field
      );
    },
    enableButton() {
      this.processing = true;
    },
    disableButton() {
      this.processing = false;
    },
    verifyAddress() {
      if(this.addresses.cep.length !== ''){
        this.disableButton();
        this.validateFormAddress("cep");
        if (this.resA.isValid()) {
          this.findByPostalCodeAction(this.addresses.cep)
            .then(() => this.setCurrentAddressAction(this.getAddressComputed))
            .then(() => this.getRegionalizationModalCallbackComputed())
            .then(() => this.closeRegionalizationModalAction(this.addresses.cep));
        } else {
          this.disableButton();
        }
      }
    }
  },
  
 mounted() {
    this.byPostalCode();
}
};
</script>  
<style lang="scss" scoped>
.text-modal-private {
  font-size: 16px;
  font-weight: bold;
  color: #1D3C85;
}
</style>