import { create, test,  enforce, only} from "vest";

export default create((data, change, currentField) => {
    only(data, currentField);

    ///Adress Validators///

    test ("cep", "Este campo não pode ser vazio", () => {
        enforce(data.cep).isNotEmpty();
    });

    test ("cep", "Este campo deve ter no minimo 8 caracteres", () => {
        enforce(data.cep).longerThanOrEquals(8);
    });

    test ("cep", " Cep inválido", () => {
        enforce(data.cep).matches(/^\d{5}-\d{3}$/);
    });
  

    test ("street", "Este campo não pode ser vazio", () => {
        enforce(data.street).isNotEmpty();
    }
    );

    test ("number", "Este campo não pode ser vazio", () => {
        enforce(data.number).isNotEmpty();
    });

    test ("number", "Este campo deve conter apenas números", () => {
        enforce(data.number).isNumeric();
    });

    test ("number", "Este campo deve ter no minimo 1 caracteres", () => {
        enforce(data.number).longerThanOrEquals(1);
    }
    );

   test ("neighborhood", "Este campo não pode ser vazio", () => {
        enforce(data.neighborhood).isNotEmpty();
    });

    test ("city", "Este campo não pode ser vazio", () => {
        enforce(data.city).isNotEmpty();
    });

    test ("state", "Este campo não pode ser vazio", () => {
        enforce(data.state).isNotEmpty();
        
    });
    /////End Adress Validators////////


});