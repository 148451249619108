<template>
 <div class="login-box" :class="{ 'error': isError, 'success': isSuccess }">
    <div class="user-box">
        <label :for="name" class="text-input" >{{ label }}</label>
      <input
        :type="type"
        :placeholder="placeholder"
        :id="name"
        :value="modelValue" 
        @input="updateValue" 
        @blur="handleBlur"
        :class="{ 'error': isError }"
        :style="{ width: isCol6 ? '88%' : '100%', backgroundColor: disabled ? '#e9ecef' : 'transparent' }"
        class="form-control"    
        :disabled="disabled"    
      />
      <small v-if="isError" class="error-message">{{ errorMessage }}</small>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    type: String,
    label: String,
    placeholder: String,
    isCol6: Boolean,
    modelValue: String,
    disabled: Boolean    
  },
   computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
         this.$emit("update:modelValue", newValue);
      }
    }
  },
  data() {
    return {
      isError: false,
      isSuccess: false,
      errorMessage: ""
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
      this.validateInput();
    },
    handleBlur() {
      this.$emit("blur", this.value);
    },
    validateInput() {
      const trimmedValue = this.value.trim();
        if (this.type === 'phone' && (trimmedValue.length < 14 || trimmedValue.length > 16)) {
          if (trimmedValue === "") {
            this.setError("Campo de telefone não pode estar vazio");
          } else {
            this.setError("O número de telefone deve conter entre 10 e 11 dígitos.");
          }
          return;
        }
        if (this.type === 'phone') {
          if (trimmedValue === "") {
            this.setError("Campo de telefone não pode estar vazio");
          } else if (!this.validatePhoneNumber(trimmedValue)) { 
            this.setError("Número de telefone inválido.");
          } else {
            this.setSuccess();
          }
          return;
        }
        if (this.type === 'text') {
          if (trimmedValue === "") {
            this.setError("Campo de telefone não pode estar vazio");
          } else if
          (trimmedValue.length < 2 || /[^a-zA-Z0-9\s]/.test(trimmedValue)) {
            this.setError("Texto inválido.Não conter caracteres especiais.");
          } else {
            this.setSuccess();
          }
        }
        if (this.type === 'email') {
          if (trimmedValue === "") {
            this.setError("Campo de email não pode estar vazio");
            return;
          } else {
            this.validateEmail();
            return;
          }
        }
        if (this.type === 'name') {
          const trimmedValue = this.value.trim();
          if (trimmedValue === "") {
            this.setError("Campo de nome não pode estar vazio");
          } else {
            const names = trimmedValue.split(' ');

            if (names.length < 2) {
              this.setError("Por favor, informe o nome e sobrenome");
            } else {
              const invalidName = names.some(name => name.length < 2);

              if (invalidName) {
                this.setError("Cada parte do nome deve ter pelo menos 2 caracteres");
              } else {
                this.setSuccess();
              }
            }
          }
          return;
        }
        if (this.type === 'cpf') {
          if (trimmedValue === "") {
            this.setError("Campo de CPF não pode estar vazio");
            return;
          } else if (!this.validateCPF(trimmedValue)) {
            this.setError("CPF inválido");
            return;
          } else {
            this.setSuccess();
            return;
          }
        }
        if (this.type === 'senha') {
              if (trimmedValue === "") {
                this.setError("Campo de senha não pode estar vazio");
              } else if (!this.validatePassword(trimmedValue)) {
                this.setError("Senha inválida.");
              } else {
                this.setSuccess();
              }
              return;
        }
        if (this.type === 'code') {
          if (trimmedValue === "") {
            this.setError("Campo inserir código não pode estar vazio");
          } else if (!this.validateCode(trimmedValue)) {
            this.setError("Código inválido.");
          } else {
            this.setSuccess();
          }
          return;
        }
          this.setSuccess();
    },
    validatePassword(password) {
      const especialCaracter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const upperLetter = /[A-Z]/.test(password);
      const number = /\d/.test(password);
      const size = password.length >= 8;
      return especialCaracter && upperLetter && number && size;
    },
    validateCode(codeToValidate) {
      const especialCaracter = /[!@#$%^&*(),.?":{}|<>]/.test(codeToValidate);
      const upperLetter = /[A-Z]/.test(codeToValidate);
      const hasNumber = /\d/.test(codeToValidate);
      const size = codeToValidate.length >= 6;
     return especialCaracter && upperLetter && hasNumber && size;
  },
    validateCPF(cpf) {
      cpf = cpf.replace(/\D/g, ''); 
        if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
          return false;
        }
        let sum = 0;
        let remainder;

        for (let i = 0; i < 9; i++) {
          sum += parseInt(cpf.charAt(i)) * (10 - i);
        }
        remainder = sum % 11;
        let digit1 = remainder < 2 ? 0 : 11 - remainder;

        if (parseInt(cpf.charAt(9)) !== digit1) {
          return false;     
        }
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += parseInt(cpf.charAt(i)) * (11 - i);
        }
        remainder = sum % 11;
        let digit2 = remainder < 2 ? 0 : 11 - remainder;
        if (parseInt(cpf.charAt(10)) !== digit2) {
          return false;
        }
        return true;
    },
    validatePhoneNumber(phoneNumber) {
      const cleanedNumber = phoneNumber.replace(/[^\d]/g, '');
      if (cleanedNumber.length < 8 || cleanedNumber.length > 11) {
        return false;
      }
      if (/^(\d)\1{5,}$|^(0+)$/.test(cleanedNumber)) {
        return false;
      }
      const afterDdd = cleanedNumber.slice(3);
      if (/^\d{9}$/.test(afterDdd) && /^(0+|1+|2+|3+|4+|5+|6+|7+|8+|9+)$/.test(afterDdd.charAt(0))) {
        return false;
      }
      const ddd = cleanedNumber.substring(0, 2);
      if (!/^\d{2}$/.test(ddd)) {
        return false;
      }
      if (cleanedNumber.length >= 11 && cleanedNumber.charAt(2) !== '9') {
        return false;
      }
      return true;      
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.value)) {
        this.setError("Email inválido");
      } else {
        this.setSuccess();
      }
    },
    setError(message) {
      this.isError = true;
      this.isSuccess = false;
      this.errorMessage = message;
    },
    setSuccess() {
      this.isError = false;
      this.isSuccess = true;
      this.errorMessage = "";
    }
  }
};
</script>
<style scoped>
.login-box .user-box input.form-control {
  width: 100%; 
  border-bottom:  1px solid darkgray;
  outline: none;
  background: transparent;
  font-size: 11px;
  color: rgb(88, 87, 87);
}
.label {
  top: 10px;
  left: 0;
  padding: 0 5px;
  font-size: 11px;
  color:  rgb(88, 87, 87);
  pointer-events: none;
  transition: .5s;
  z-index: 1;
}
.login-box .user-box .fas.fa-check-circle {
  color: #2ecc71;
}
.login-box .user-box .fas.fa-exclamation-circle {
  color: #e40f0f; 
}
.error-message {
  color: #e40f0f; 
}
.login-box .user-box input.error {
  border: 1px solid #e40f0f
}
.text-input {
  font-size: 11px;
  color: rgb(88, 87, 87);
}
.login-box .user-box input.success-border {
  border: 1px solid #2ecc71;
}
</style>