<template>
  <Modal :modal-active="showModalSendEmailSuccess">
    <template v-slot:default="{}">
      <div class="container text-center mt-3 col-12">
        <div class="row">
          <Icon name="icon-verify" class="icon-center text-center" />
          <span class="container-fluid mt-1 text-center">
            <span class="text-verify">Sucesso!!</span>
          </span>
        </div>
        <div class="mt-2 text-center text">
          Seu email foi enviado com sucesso!
        </div>
        <div class="container mt-3">
          <div class="d-flex justify-content-around mt-4 mb-3 row">
            <button
              type="button"
              class="btn border rounded button-accept col-5"
              @click="updatePassword">
              Concluir
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>  
<script>
import { mapActions } from "vuex";
import Modal from "@/components/Modal/Modal.vue";
import Icon from "@/components/Icon/Icon.vue"
export default {
  name: "ModalConfirmSendEmail",
  data() {
    return {
      showModalSendEmailSuccess: true,
    };
  },
  components: {
    Modal,
    Icon
  },
  methods: {
    ...mapActions({
      closeCartAction: 'cart/closeCart'
    }),
    closeCart() {
      this.closeCartAction()
    },
    updatePassword() {
      this.showModalSendEmailSuccess = false;
      this.closeCart()
      this.$router.push({ name: "EsqueciSenha" });
    }
  }
};
</script>
<style lang="scss" scoped>
.text-verify {
  font-size: 25px;
  font-weight: 700;
  color: #0f7c5c;
}
.button-accept {
  background-color: #1d3c85;
  color: #fff;
}
</style>
  
  