export async function getNavigation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          console.error("Geolocation error:", error);
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocalização não é suportada neste navegador."));
    }
  });
}  
export async function getDeviceByIp() {
  try {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    throw new Error("Não foi possível obter o IP do usuário.");
  }
}  
export async function getAndDisplayLocationInfo(position) {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;
  try {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
    const data = await response.json();
    
    if (data && data.address && data.display_name) {
      const locationName = data.display_name;
      const address = data.address;
      return { locationName, address };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter informações de localização:", error);
    return null;
  }
}
  export function locationError(error) {
    if (error && error.message) {
      console.error("Erro ao obter a localização: ", error.message);
    } else {
      console.error("Erro desconhecido ao obter a localização.");
    }
  }
  