<template>
  <Modal :modal-active="showCreateUserModal">
		<template v-slot:default="{ }">
			<div class="py-3 " style="margin-left: unset;">
				<div class="row  ">
          <div class="row col-12 Text">
            <span class="container-fluid mt-1 text-login ">
              Criar usuário
              <i class="fas fa-times icon-close  close-button" @click="closeModalCreateUser"></i>
            </span>               
          </div>
          <div class="mt-2 text-description">
            Precisamos de alguns dados para criarmos sua conta TradeAppOne.
          </div> 
          <div class="mt-2">
            <Input 
              label="CPF*"  
              type="cpf"
              v-model="customer.cpf"
              v-mask="'###.###.###-##'" 
              :disabled="true"
            />
          </div>
        <button
          class="btn border rounded col-5 mt-2 mb-4 mx-2" style="background-color: #9db8f7; color: #fff;"
          @click="returnLogin">
          Voltar
        </button>
          <button
            type="button"
            @click="nextModalCustomer"
            class="btn border rounded col-6 mt-2 mb-4 style-button">
            Continuar
          </button>
        </div>
      </div>       
    </template>        
  </Modal>
  <div>
    <VerificationTokenSendEmail 
      v-if="showSendCodeModal"
      :closeModalSendEmail="closeModalSendEmail"
      @returnShowModalCustomerCreate="hideCreateUserShow"
      @openModalPassword2="hideopenModalPassword"  
    />         
  </div>  
  <div>
    <CreatePassword 
      v-if="showCreatePasswordModal"
      :closeModalCreatePassword="closeModalCreatePassword"
    />
  </div>   
  <Modal :modal-active="showModalCustomerCreate">    
    <template  v-slot:default="{ }">
      <div class="row col-12 Text mt-3">
        <span class="container-fluid mt-1 text-step ">
          Agora, precisamos dos dados:
          <i class="fas fa-times icon-close  close-button" @click="closeModalCreateUser"></i>
        </span>               
      </div>
      <Input 
        label="Nome*"  
        type="name" 
        v-model="customer.name"
        :disabled="true"
      />
      <Input 
        label="Nome da mãe*"  
        type="name" 
        v-model="customer.motherName"
        :disabled="true"
      />
      <Input 
        label="Data de nascimento*"  
        type="date" 
        v-model="customer.birthdate"
        :disabled="true"
      />
      <Input 
        label="Email*"  
        type="email" 
        v-model="customer.email"
        :disabled="true"
      />
      <Input 
        label=" Telefone*"  
        type="phone"
        v-model="customer.phone1"
        v-mask="'(##) #####-####'"
        placeholder="Digite o número de telefone"
        :disabled="false"
      />      
      <div class="col-12 mt-3">
        <button
          class="btn border rounded col-5 mt-2 mb-4 mx-2" style="background-color: #9db8f7; color: #fff;"
          @click="returCreateUserCPF">
          Voltar
        </button>
        <button
          type="button"
          @click="createUser"
          class="btn border rounded col-6 mt-2 mb-4 style-button">
          Continuar
        </button>       
      </div>   
    </template>
  </Modal>        
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal/Modal";
import Input from "@/components/Form/Input/Input.vue";
import VerificationTokenSendEmail from "@/components/Login/VerificationTokenSendEmail.vue";
import CreatePassword from "@/components/Login/CreatePassword.vue";
export default {
  name: "CreateUser",
  emits: ['returnLogin'],
  data(){
		return{
      showModalCustomerCreate:false,
      showCreateUserModal: true, 
      showCreatePasswordModal: false,
      showSendCodeModal:false,
      customer:{ 
        cpf: '',
        name: '',
        motherName: '',
        birthdate: '',
        phone1: '',
        email: '',
        id: ''
      },
      tagOrigin: "Fluxo de Vendas do TradeAppOne"
    };
  },
  components:{
    Modal,
    Input,
    VerificationTokenSendEmail,
    CreatePassword
  },
  props:{
    closeModalCreateUser:{
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      getShowClaroFieldsComputed:"address/getShowClaroFields"
    })
  },
  methods: {
    ...mapActions({
      customerSaveAction:'customer/save'
    }),
    hideopenModalPassword(){ 
      this.showSendCodeModal = false;  
      this.showCreatePasswordModal = true;
    },
    openModalSend(){
      this.showModalCustomerCreate =  false;
      this.showSendCodeModal = true;
    },
    nextModalCustomer(){
      this.showCreateUserModal = false;
      this.showModalCustomerCreate = true;
    },
    closeModalCreatePassword(){
      this.showCreatePasswordModal = false;
    },
    closeModalSendEmail(){
      this.showSendCodeModal = false;
    },
    createUser(){
      this.removeMaskByApi();
      if (this.isAllFieldsFilled()){
        this.customerSaveAction(this.getCustomerPayloaded());
        this.openModalSend()
      }else{
        this.$swal("Atenção!", "Você precisa preencher o campo de telefone.", "warning");
      }
    },
    isAllFieldsFilled(){
      if (this.customer.phone1 !== ""){
        return true
      }else{
        return false
      }
    },
    getCustomerPayloaded(){
      return {
        cpf: this.customer.cpf,
        name: this.customer.name,
        email: this.customer.email,
				phone: this.customer.phone1,
        motherName: this.customer.motherName,
        birthdate: this.customer.birthdate,
        id: this.customer.id
      }
    },
    removeMaskByApi(){
      if (this.customer.phone1){
        this.customer.phone1.replace(/[\s().-]/g, "");
      }
      if (this.customer.cpf){
        this.customer.cpf = this.customer.cpf.replace(/[.-]/g, "");
      }
    },  
    returnLogin(){
      this.showCreateUserModal = false;
      this.$emit('returnLogin')
    },
    hideCreateUserShow(){
      this.showModalCustomerCreate= true;
      this.showSendCodeModal = false;
    },
    hideReturnSendCode(){
      this.showCreatePasswordModal = false;
      this.showSendCodeModal = true;
    },
    returCreateUserCPF(){
      this.showModalCustomerCreate = false;
      this.showCreateUserModal = true;
    },
    getDataCustomer(){ 
      if(this.getShowClaroFieldsComputed !== false){
        this.showClaro = true;
        const customer = this.$store.getters["customer/getCustomer"];     
          if(customer !== null){
            this.customer.cpf = customer.cpf;
            this.customer.name = customer.name;
            this.customer.email = customer.email;
            this.customer.birthdate = customer.birthdate;
            this.customer.motherName = customer.motherName;
            this.customer.id = customer.id;          
          }
      }else{
        return
      }     
    }
  },
  created(){
    this.getDataCustomer(); 
  }
};
</script>
<style lang="scss" scoped>
.text-step{
	font-size: 17px;
	color: #1d3c85;
  font-weight: 500;
}
.text {
	color: #070707e7;
	font-size: 12rem;
}
.text-description{
  color: #141414; 
  font-size: 11px;
}
.style-button{
  color: #fff;
  background-color: #1d3c85;
}
.icon-close{
  float: right;
  margin-right: -22px;
  cursor: pointer;
  color: #1d3c85;
  font-size: 16px
}
.close-button {
  display: inline-block;
  top: 0;
  right: 0;
  width: 25px;
  height: 26px;
  background-color: #fff;
  border: 2px solid #1d3c85;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-login{
  color: #1d3c85; 
  font-size: 20px; 
  font-weight: 500;
}
</style>