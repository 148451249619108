<template>
  <Modal :modal-active="showSendCodeModal">
		<template v-slot:default="{ }">
			<div class="row mt-1 py-3" style="margin-left: unset;">
				<div class="mt-2">
          <div class="row col-12 Text">
            <span class="container-fluid text-step ">
            Verificar endereço de email
            <i class="fas fa-times icon-close  close-button" @click="closeModalSendEmail"></i>
            </span>               
          </div>
					<div class="mt-2">
						<div class="row ">
							<div style="color: #141414; font-size: 12px">
								Para verificar seu email, enviamos<br> um código para o email: {{ customer.email }}
								<a class="text-link" @click="returnUserModal">(Alterar)</a>
							</div>
						</div>
						<div class="mt-3">
							<b-button @click="sendCode" :disabled="timerRest > 0">Enviar Código</b-button>
							<p class="mt-2" v-if="timerRest > 0">Espere {{ timerRest }} segundos antes de reenviar.</p>
						</div>
					</div>
					<div class="mt-3">
						<span class=" text-input"> Inserir código* </span>
						<input class="form-control text-input" placeholder="**********" v-model="customer.code" type="text"/>
					</div>
          <button
            class="btn border rounded col-5 mt-3 mb-4 mx-2" style="background-color: #9db8f7; color: #fff;"
            @click="returnShowModalCustomerCreate">
            Voltar
          </button>
          <button
            type="button"
            @click="closeSendCodeModal"
            class="btn border rounded col-6 mt-3 mb-4 style-button">
            Verificar
          </button>
				</div>   
			</div>
		</template>
	</Modal>
</template>
<script>
import {  mapGetters } from "vuex";
import Modal from "@/components/Modal/Modal";
import suite from "@/services/validations/customerValidations.js";
import { validateEmail, reSendEmail } from "@/modules/auth/services/auth.customer.service.js";
export default {  
  name: "TokenSendEmail",
  data(){
		return{
      emailError: false,
      try: 0,
      timerRest: 0,
      timer: null,
      showSendCodeModal: true,
      customer:{
        email: "",
        id: "",
        code: ""
      },
      tagOrigin: "Fluxo de Vendas do TradeAppOne",
      res: suite.get(),
    };
  },
  components:{
    Modal    
  },
  props:{
    closeModalSendEmail:{
      type: Function
    }
  },
  computed:{
    ...mapGetters({
      customerComputed: 'customer/getCustomer'
    })
  },
  methods:{
    openModalPassword2(){      
      this.$emit('openModalPassword2');
    }, 
    getCustomerPayload(){
      return{
        id: this.customerComputed?.id || null
      }
    },
    getValidateEmailPayload(){
      return{
        id: this.customerComputed?.id,
        code: this.customer.code,
      }
    },
    getPasswordPayload(){
      return{
        customer: this.customer.id,
        password: this.customer.password,
        confirmPassword: this.customer.confirmPassword,
      }
    },
    closeSendCodeModal(){
      this.validateEmailCustomer()
      this.showSendCodeModal = false;
    },
    returnUserModal(){
      this.showSendCodeModal = false;
      this.showModalCustomerCreate = true;      
    },
    returnShowModalCustomerCreate(){
      this.showSendCodeModal= false;
      this.$emit('returnShowModalCustomerCreate')
    },    
    async sendCode(){
      try{
        const response = await reSendEmail(this.getCustomerPayload());
        if(this.try < 2 && response){
            this.$swal("Código enviado com sucesso!", " ", "success");
            this.timerRest = 45;
            this.timer = setInterval(() => {
            if (this.timerRest > 0){
              this.timerRest--;
            }else{
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }else{
          this.$swal("Você excedeu o número máximo de tentativas.", " ", "error");
        }
        this.try++;
      }catch (error){
        console.error("Erro ao enviar código:", error);
      }
    },
    async validateEmailCustomer(){
      try{
        const response = await validateEmail(this.getValidateEmailPayload());
        if(response){
          this.$swal("Email validado com sucesso!", " ", "success");
          this.openModalPassword2();
        }else{
          this.$swal("deu errado");
        }        
      }catch(error){
        this.$swal("Código inválido, por favor, digite um código válido.", " ", "error");
        this.showSendCodeModal=true;
        console.log(error);
      }
    },
    validateEmail(){
      const emailPattern = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
      if (!emailPattern.test(this.customer.email)){
        this.emailError = true;
      }else{
        this.emailError = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.text-input{
	font-size: 11px;
	color: rgb(88, 87, 87);
}
.text-link{
	font-size: 11px;
	color: #1d3c85;
}
.text{
	color: #070707e7;
	font-size: 12rem;
}
.style-button{
  color: #fff;
  background-color: #1d3c85;
}
.text-step{
	font-size: 17px;
	color: #1d3c85;
  font-weight: 500;
}
.icon-close{
  float: right;
  margin-right: -22px;
  cursor: pointer;
  color: #1d3c85;
  font-size: 16px
}
.close-button{
  display: inline-block;
  top: 0;
  right: 0;
  width: 25px;
  height: 26px;
  background-color: #fff;
  border: 2px solid #1d3c85;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>