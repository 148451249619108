import { create, test, enforce, only } from "vest";

const EMAIL_REGEX = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
const PHONE_NUMBER_REGEX = /^\(?\d{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/;

export default create((data, currentField) => {
  only(currentField, data);

  test("name", "Este campo não pode ser vazio", () => {
    enforce(data.name).isNotEmpty();
  });

  test("name", "Nome deve ter no mínimo 3 caracteres", () => {
    enforce(data.name)
    .isString()
    .isNotBlank()
    .longerThanOrEquals(3);
  });

  test('name', 'Nome não pode conter números', () => {
    enforce(data.name).isNotNumber().isNotNumeric();
  });

  test("motherName", "Nome deve ter no mínimo 2 caracteres", () => {
    enforce(data.motherName).longerThanOrEquals(2);
  });

  test("email", "Este é não pode ser vazio", () => {
    enforce(data.email).isNotEmpty();
  });

  test("email", "Este campo deve ser um email válido", () => {
    enforce(data.email).matches(EMAIL_REGEX);
  });

  test("phone1", "Este campo não pode ser vazio", () => {
    enforce(data.phone1).isNotEmpty();
  });

  test("phone1", "Este campo deve ter no minimo 11 caracteres", () => {
    enforce(data.phone1).longerThanOrEquals(11);
  });

  test("phone1", "Número de telefone inválido...", () => {
    enforce(data.phone1).matches(PHONE_NUMBER_REGEX);
  });

  test("cpf", "Este campo não pode ser vazio", () => {
    enforce(data.cpf).isNotEmpty();
  });

  test("cpf", "Este campo deve ter no minimo 11 caracteres", () => {
    enforce(data.cpf).longerThanOrEquals(11);
  });
});
