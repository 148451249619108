<template>
  <Modal :modal-active="showLoginModal">
    <template v-slot:default="{}">
      <div class="row mt-0" style="margin-left: unset">
        <div class="row col-12  container mt-1">
          <span class="container-fluid mt-3 text-login ">
            Fazer login
            <i class="fas fa-times icon-close  close-button" @click="close"></i>
          </span>               
        </div>
        <div class="mt-2 text-description">
          Coloque seu usuário e senha para acessar sua conta TradeAppOne e
          concluir a compra
        </div>      
        <div class="container mt-2">
            <Input 
              label="Email*"  
              type="email" 
              placeholder="email@email.com.br" 
              v-model="customer.email"
            />
          <div class="mt-2">
            <button
              class="btn border rounded col-12 style-button"
              @click="openPasswordModal">
                Continuar
            </button>
            <hr />
            <div class="mt-2 text-center">
              <span class="mt-2 text-center text-description">
                Você ainda não tem conta TradeAppOne?
              </span>
            </div>
            <button
              class="btn border rounded col-12 mt-2 mb-3 style-button-white"
              @click="createUser">
              Criar usuário
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal :modal-active="showPasswordModal">
    <template v-slot:default="{}">
      <div class="row mt-2" style="margin-left: unset">
        <div class="">
          <div class="row col-12   mt-1">
            <span class="container-fluid mt-2 text-login ">
              Fazer login
              <i class="fas fa-times icon-close  close-button" @click="close"></i>
            </span>               
          </div>
          <div class="text-center mt-2">
            <div class="d-flex justify-content-between align-items-center">
              <div class="text-email">
                {{ customer.email }}
              </div>
              <div>
                <a class="text-link" @click="returnLoginModal">Alterar</a>
              </div>
            </div>
          </div>
          <div class="mt-2 row">
            <span class="text-input">  </span>
            <div>
              <Input 
                label="Senha*"  
                placeholder="Senha" 
                v-model="customer.password"
                :type="showTypePassword ? 'text' : 'password'"
              />
              <span @click="togglePasswordVisibility" class="px-1">
                <i class="icon-senha" :class="showTypePassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              </span>
            </div>           
          </div>
          <div>
            <a class="text-link" @click="forgotPassword">
              Esqueci minha senha
            </a>
          </div>
          <div class="col-12 row justify-content-center">
           <button
            class="btn border rounded col-5 mt-2 mb-4 mx-2" style="background-color: #9db8f7; color: #fff;"
            @click="returLogin">
             Voltar
          </button>
          <button
            class="btn border rounded col-6 mt-2 mb-4 style-button"
            @click="closePasswordModal">
             Continuar
          </button>
          </div>
        </div>
      </div>
    </template>
   </Modal>
  <CreateUser @returnLogin="hideCreateUserShowLogin" v-if="showCreateUserModal" :closeModalCreateUser="closeModalCreateUser"/>  
  <SendTokenEmailChangePassword v-if="showModalSendTokenEmailChangePassword"  @returnLogin="returnLoginModalBySendTokenEmail" :closeSendTokenEmail="closeSendTokenEmail"/>  
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal/Modal";
import CreateUser from "@/components/Login/CreateUser.vue";
import SendTokenEmailChangePassword from "@/components/Login/SendTokenEmailChangePassword.vue";
import Input from "@/components/Form/Input/Input.vue";
import { verifyLogin }from "@/modules/auth/services/auth.customer.service.js"
export default {
  name: "Login",
  components: {
    Modal,
    CreateUser,
    SendTokenEmailChangePassword,
    Input
  },
  data() {
    return {
      showCreateUserModal: false,
      showLoginModal: true,
      showPasswordModal: false,
      showTypePassword: false,
      showModalSendTokenEmailChangePassword:false,
      customer: {
        email: "",
        password: "",
        id:""
      },
      customerId:"",
      tagOrigin: "Fluxo de Vendas do TradeAppOne"
    };
  },
  computed: {
    ...mapGetters({
      customerComputed: "customer/getCustomer",
      customerAddressComputed: "customer/getCustomerAddress",
      isAuthenticatedCustomerComputed: "auth/isAuthorizationCustomer",
    })
  },
  props:{
     close: {
      type: Function
    }
  },
  methods: {
    ...mapActions({
      closeCartAction: "cart/closeCart",
      authenticateUserAction: "auth/authenticateUser"
    }),
    getUserPayload() {
      return {
        email: this.customer.email,
        password: this.customer.password,
      };
    },
    openPasswordModal() {
      this.showPasswordModal = true;
      this.showLoginModal = false;
    },
    returnLoginModal() {
      this.showPasswordModal = false;
      this.showLoginModal = true;
    },
    returnLoginModalBySendTokenEmail(){
      this.showModalSendTokenEmailChangePassword = false;
      this.showLoginModal = true;

    },
    async closePasswordModal() {
      try {
        await this.authenticateUserAction(this.getUserPayload());
        if (this.isAuthenticatedCustomerComputed === true) {
          this.showPasswordModal = false;
          this.showLoginModal = false;
          return this.$swal("Login realizado com sucesso!", " ", "success");
        }
      } catch (error) {
        this.$swal("Usuário ou senha inválidos.", " ", "error");
        console.error("Erro durante a autenticação:", error);
      }
    },
    async createUser() {
      try {
        const response = await verifyLogin(this.getCustomerPayload());
          if (response.exist === true) {
             this.showCreateUserModal = false;
             this.$swal("Usuário já possui cadastro.", " ", "error")
          }else {
           this.openUserModal()
          }
        } catch (error) {
        console.error("Erro ao verificar o login:", error);
      }
    },
    getCustomerPayload() {
      return {
        id: this.customerComputed?.id || null
      }
    },
    openUserModal() {
      this.showCreateUserModal = true;
      this.showLoginModal = false;
    },
    forgotPassword() {
      this.showPasswordModal = false;
      this.showModalSendTokenEmailChangePassword = true;
    },

    togglePasswordVisibility() {
      this.showTypePassword = !this.showTypePassword;
    },
    returLogin(){
      this.showPasswordModal = false;
      this.showLoginModal = true;
    },
    closeModalCreateUser(){
      this.showCreateUserModal=false;
    },
    closeSendTokenEmail(){
      this.showModalSendTokenEmailChangePassword = false;
    },
    hideCreateUserShowLogin(){
      this.showCreateUserModal = false
      this.showLoginModal= true;
    }    
  }
};
</script>
<style lang="scss" scoped>
.text-modal-private {
  font-size: 18px;
  font-weight: bold;
  color: #1d3c85;
}
.text-input {
  font-size: 11px;
  color: rgb(88, 87, 87);
}
.text-link {
  font-size: 11px;
  color: #1d3c85;
}
.text {
  color: #070707e7;
  font-size: 12rem;
}
.buttom-next {
  color: #fff;
  background-color: #1d3c85;
}
.text-alert-password {
  color: crimson;
  font-size: 10px;
}
.error-message {
  color: red;
  font-size: 11px;
}
.icon-senha {
  font-size: 14px;
  position: absolute;
  top: 49%;
  right: 40px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}
.text-description{
  color: #141414; 
  font-size: 11px;
}
.text-login{
  color: #1d3c85; 
  font-size: 20px; 
  font-weight: 500;
}
.style-button{
  color: #fff;
  background-color: #1d3c85;
}
.text-email{
  color: #141414;
  font-size: 12px;
}
.style-button-white{
  color: #1d3c85;
  background-color: #ffff
}
.icon-close{
  float: right;
  margin-right: -22px;
  cursor: pointer;
  color: #1d3c85;
  font-size: 16px
}
.close-button{
  display: inline-block;
  top: 0;
  right: 0;
  width: 26px;
  height: 27px;
  background-color: #fff;
  border: 2px solid #1d3c85;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

